const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "home" */"@/common/sign_in.vue")
	},
	{
		path: "/:catchAll(.*)",
		component: () => import(/* webpackChunkName: "home" */"@/components/error_description.vue")
	},
	// 后台路由
	{
		path: '/admin',
		component: () => import(/* webpackChunkName: "home" */"@/admin/main.vue"),
		props: true,
		children: [
			{
				path: 'index/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/index/index.vue"),
			},
			{
				path: 'user/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/user/index.vue"),
			},
			{
				path: 'setup/config',
				component: () => import(/* webpackChunkName: "home" */"@/admin/setup/config.vue"),
			},
			{
				path: 'setup/wxapp',
				component: () => import(/* webpackChunkName: "home" */"@/admin/setup/wxapp.vue"),
			},
			{
				path: 'setup/cms',
				component: () => import(/* webpackChunkName: "home" */"@/admin/setup/cms.vue"),
			},
			{
				path: 'setup/tmpl',
				component: () => import(/* webpackChunkName: "home" */"@/admin/setup/tmpl.vue"),
			},
			{
				path: 'setup/iss',
				component: () => import(/* webpackChunkName: "home" */"@/admin/setup/iss.vue"),
			},
			{
				path: 'store/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/store/index.vue"),
			},
			{
				path: 'store/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/store/edit.vue"),
			},
			{
				path: 'store/polygon',
				component: () => import(/* webpackChunkName: "home" */"@/admin/store/polygon.vue"),
			},
			{
				path: 'store/classify',
				component: () => import(/* webpackChunkName: "home" */"@/admin/store/classify.vue"),
			},
			{
				path: 'store/classify/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/store/classifyEdit.vue"),
			},
			{
				path: 'payment/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/payment/index.vue"),
			},
			{
				path: 'payment/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/payment/edit.vue"),
			},
			{
				path: 'payment/setup',
				component: () => import(/* webpackChunkName: "home" */"@/admin/payment/setup.vue"),
			},
			{
				path: 'diy/banner',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/banner.vue"),
			},
			{
				path: 'diy/banner/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/bannerEdit.vue"),
			},
			{
				path: 'diy/ad',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/ad.vue"),
			},
			{
				path: 'diy/ad/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/adEdit.vue"),
			},
			{
				path: 'diy/icon',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/icon.vue"),
			},
			{
				path: 'diy/icon/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/diy/iconEdit.vue"),
			},
			{
				path: 'cron/tasks',
				component: () => import(/* webpackChunkName: "home" */"@/admin/cron/tasks.vue"),
			},
			{
				path: 'cron/tasks/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/cron/tasksEdit.vue"),
			},
			{
				path: 'cron/tasks/log',
				component: () => import(/* webpackChunkName: "home" */"@/admin/cron/log.vue"),
			},
			{
				path: 'order/list',
				component: () => import(/* webpackChunkName: "home" */"@/admin/order/orderList.vue"),
			},
			{
				path: 'order/detail',
				component: () => import(/* webpackChunkName: "home" */"@/admin/order/orderDetail.vue"),
			},
			{
				path: 'withdrawal/index',
				component: () => import(/* webpackChunkName: "home" */"@/admin/withdrawal/index.vue"),
			},
			{
				path: 'withdrawal/log',
				component: () => import(/* webpackChunkName: "home" */"@/admin/withdrawal/log.vue"),
			},
			{
				path: 'withdrawal/binding',
				component: () => import(/* webpackChunkName: "home" */"@/admin/withdrawal/binding.vue"),
			},
			{
				path: 'withdrawal/bank',
				component: () => import(/* webpackChunkName: "home" */"@/admin/withdrawal/bank.vue"),
			},
			{
				path: 'withdrawal/bank/edit',
				component: () => import(/* webpackChunkName: "home" */"@/admin/withdrawal/bankEdit.vue"),
			},
		]
	},
	// 商家路由
	{
		path: '/store',
		component: () => import(/* webpackChunkName: "home" */"@/store/main.vue"),
		props: true,
		children: [
			{
				path: 'index/index',
				component: () => import(/* webpackChunkName: "home" */"@/store/index/index.vue"),
			},
			{
				path: 'goods/index',
				component: () => import(/* webpackChunkName: "home" */"@/store/goods/index.vue"),
			},
			{
				path: 'goods/edit',
				component: () => import(/* webpackChunkName: "home" */"@/store/goods/edit.vue"),
			},
			{
				path: 'goods/classify',
				component: () => import(/* webpackChunkName: "home" */"@/store/goods/classify.vue"),
			},
			{
				path: 'goods/classify/edit',
				component: () => import(/* webpackChunkName: "home" */"@/store/goods/classifyEdit.vue"),
			},
			{
				path: 'setup/index',
				component: () => import(/* webpackChunkName: "home" */"@/store/setup/index.vue"),
			},
			{
				path: 'setup/config',
				component: () => import(/* webpackChunkName: "home" */"@/store/setup/config.vue"),
			},
			{
				path: 'setup/freight',
				component: () => import(/* webpackChunkName: "home" */"@/store/setup/freight.vue"),
			},
			{
				path: 'setup/cms',
				component: () => import(/* webpackChunkName: "home" */"@/store/setup/cms.vue"),
			},
			{
				path: 'setup/tmpl',
				component: () => import(/* webpackChunkName: "home" */"@/store/setup/tmpl.vue"),
			},
			{
				path: 'setup/delivery',
				component: () => import(/* webpackChunkName: "home" */"@/store/setup/delivery.vue"),
			},
			{
				path: 'order/list',
				component: () => import(/* webpackChunkName: "home" */"@/store/order/orderList.vue"),
			},
			{
				path: 'order/detail',
				component: () => import(/* webpackChunkName: "home" */"@/store/order/orderDetail.vue"),
			},
			{
				path: 'withdrawal/index',
				component: () => import(/* webpackChunkName: "home" */"@/store/withdrawal/index.vue"),
			},
			{
				path: 'fund/index',
				component: () => import(/* webpackChunkName: "home" */"@/store/withdrawal/fund.vue"),
			},
			{
				path: 'fund/log',
				component: () => import(/* webpackChunkName: "home" */"@/store/withdrawal/log.vue"),
			},
		]
	},
]
export default routes //导出
